import { useMemo } from "react";
import { css } from "@emotion/react";
import { ButtonIcon, ButtonPane, CallToAction, Card, Section } from "../components";
import Theme from "../components/Theme";
import { useCarousel } from "../core";
import IconArrowL from "../images/arrow-l.inline.svg";
import IconArrowR from "../images/arrow-r.inline.svg";
import { useQuotesData } from "../queries";
import { TestimonialType } from "../queries/DataTypes";

const styles = {
  layout: css`
    margin-top: 36px;

    ${Theme.mq.lg_} {
      display: grid;
      grid-template-columns: minmax(320px, 2fr) 4fr;
      grid-gap: 48px;
      margin-top: 48px;
    }

    ${Theme.mq.xl} {
      grid-gap: 64px;
    }
  `,
  selector: css`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 12px;
    padding: 8px;

    ${Theme.mq._md} {
      display: none;
    }
  `,
  card: css`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    min-height: 294px;
    padding: 32px;

    ${Theme.mq.lg_} {
      min-height: 312px;
      padding: 48px;
    }

    ${Theme.mq.xl} {
      min-height: 330px;
      padding: 64px;
    }
  `,
  header: css`
    margin-bottom: 24px;
  `,
  carousel: css`
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;

    > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 100%;
      position: absolute;
    }
  `,
  quote: css`
    width: 100%;
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  content: css`
    ${Theme.typography.body.regular};
    color: ${Theme.palette.muted};
  `,
  author: css`
    display: flex;
    align-items: baseline;
    margin-right: 64px;

    ${Theme.mq._sm} {
      margin-right: 48px;
    }

    ${Theme.mq.xs} {
      flex-direction: column;
    }
  `,
  name: css`
    ${Theme.typography.body.bold};
    color: ${Theme.palette.text};
  `,
  moniker: css`
    ${Theme.typography.caps};
    color: ${Theme.palette.muted};

    ${Theme.mq.sm_} {
      margin-left: 6px;
      ::before {
        content: "/";
      }
    }
  `,
  arrows: css`
    position: absolute;
    display: flex;
    align-items: center;
    button + button {
      margin-left: 16px;
    }

    bottom: 32px;
    right: 32px;

    ${Theme.mq._sm} {
      > button {
        width: 18px;
        height: 18px;
      }

      button + button {
        margin-left: 12px;
      }
    }

    ${Theme.mq.lg_} {
      bottom: 48px;
      right: 48px;
    }

    ${Theme.mq.xl} {
      bottom: 64px;
      right: 64px;
    }
  `,
};

interface QuoteProps {
  name: string;
  moniker?: string;
  content: string;
}

const Quote = ({ name, moniker, content }: QuoteProps) => (
  <div css={styles.quote}>
    <div css={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
    <div css={styles.author}>
      <span css={styles.name}>{name}</span>
      {moniker && <span css={styles.moniker}>{moniker}</span>}
    </div>
  </div>
);

const TestimonialTypeName = {
  appteams: "application teams",
  infrateams: "infrastructure teams",
  advisors: "trusted advisors",
};

type Item = {
  type: TestimonialType;
  name: string;
  moniker?: string;
  content: string;
};

export interface SectionHomeTestimonialsProps {
  heading: string;
  content?: string;
  quoteRefs: Array<string>;
}

function order(type: TestimonialType) {
  switch (type) {
    case "appteams":
      return 1;
    case "infrateams":
      return 2;
    case "advisors":
      return 3;
  }
}

export const SectionHomeTestimonials = ({ heading, content, quoteRefs }: SectionHomeTestimonialsProps) => {
  const quotesData = useQuotesData();
  const [items, jumps] = useMemo(() => {
    var set = new Set(quoteRefs);
    const items = quotesData.quotes.filter(({ id }) => set.has(id));
    items.sort((a, b) => order(a.type) - order(b.type));

    const jumps = [0];
    for (let i = 1; i < items.length; ++i) {
      if (items[i - 1].type !== items[i].type) {
        jumps.push(i);
      }
    }

    return [items, jumps];
  }, [quotesData, quoteRefs]);

  const [active, desired, prev, next, jump, style] = useCarousel(items.length, 10000);
  const current = items[desired];

  return (
    <Section marginTop marginBottom>
      <CallToAction width="50%" appearance="secondary" heading={heading} content={content} />
      <div css={styles.layout}>
        <Card sx={styles.selector}>
          <ButtonPane
            checked={current.type === "appteams"}
            header={`With ${TestimonialTypeName["appteams"]}`}
            onClick={() => jump(jumps[0])}
          />
          <ButtonPane
            checked={current.type === "infrateams"}
            header={`With ${TestimonialTypeName["infrateams"]}`}
            onClick={() => jump(jumps[1])}
          />
          <ButtonPane
            checked={current.type === "advisors"}
            header={`With ${TestimonialTypeName["advisors"]}`}
            onClick={() => jump(jumps[2])}
          />
        </Card>
        <Card sx={styles.card}>
          <h6 css={[Theme.typography.h6, styles.header]}>With {TestimonialTypeName[current.type]}</h6>
          <div css={styles.carousel}>
            <div style={style}>
              {items.length > 1 && <Quote {...items[items.length - 1]} />}
              {items.map((item, index) => (
                <Quote key={`i${index}`} {...item} />
              ))}
              {items.length > 1 && <Quote {...items[0]} />}
            </div>
          </div>
          {items.length > 1 && (
            <div css={styles.arrows}>
              <ButtonIcon width={24} height={24} onClick={prev}>
                <IconArrowL />
              </ButtonIcon>
              <ButtonIcon width={24} height={24} onClick={next}>
                <IconArrowR />
              </ButtonIcon>
            </div>
          )}
        </Card>
      </div>
    </Section>
  );
};

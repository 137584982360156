import { css } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";
import { CallToAction, Section } from "../components";
import Theme from "../components/Theme";
import { CallToActionData } from "../queries/DataTypes";

const styles = {
  layout: css`
    display: grid;
    grid-gap: 48px;
    align-items: center;
    grid-auto-flow: row;

    ${Theme.mq.sm_} {
      grid-template-columns: minmax(250px, 1fr) minmax(auto, 641px);
    }
  `,
};

export const SectionHomeJoin = (props: CallToActionData) => {
  return (
    <Section marginTop marginBottom sx={styles.layout}>
      <CallToAction appearance="h4" {...props} />
      <StaticImage
        src="../../static/assets/planet.png"
        alt="Join teams across the globe"
        width={641}
        height={574}
        objectFit="contain"
        placeholder="blurred"
      />
    </Section>
  );
};

import { graphql } from "gatsby";
import { BlockMoreProps } from "../components";
import { PageLayout } from "../layouts/Page";
import { CallToActionData } from "../queries/DataTypes";
import { SectionHomeDiagram } from "../sections/HomeDiagram";
import { SectionHomeHero } from "../sections/HomeHero";
import { SectionHomeJoin } from "../sections/HomeJoin";
import { SectionHomeLogo } from "../sections/HomeLogo";
import { SectionHomeMore } from "../sections/HomeMore";
import { SectionHomeTestimonials } from "../sections/HomeTestimonials";

interface PageProps {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        description: string;
        keywords: string;
        hero: CallToActionData;
        testimonials?: {
          heading: string;
          content: string;
          quoteRefs: Array<string>;
        };
        more?: BlockMoreProps;
        platform?: {
          heading: string;
          left: Array<string>;
          right: Array<{
            heading: string;
            content: string;
          }>;
        };
        logo?: Array<{
          title: string;
          image: string;
          scale?: number;
        }>;
        join?: CallToActionData;
      };
    };
  };
}

export default ({
  data: {
    mdx: {
      frontmatter: { title, description, keywords, hero, testimonials, more, platform, logo, join },
    },
  },
}: PageProps) => (
  <PageLayout title={title} description={description} keywords={keywords}>
    {hero && <SectionHomeHero {...hero} />}
    <main>
      {testimonials && testimonials.quoteRefs.length > 0 && <SectionHomeTestimonials {...testimonials} />}
      {more && <SectionHomeMore {...more} />}
      {platform && <SectionHomeDiagram {...platform} />}
      {logo && logo.length > 0 && <SectionHomeLogo logo={logo} />}
    </main>
    {join && <SectionHomeJoin {...join} />}
  </PageLayout>
);

export const query = graphql`
  query IndexQuery {
    mdx(fileAbsolutePath: { glob: "**/pages/index.md" }) {
      frontmatter {
        title
        description
        keywords
        hero {
          heading
          prehead
          content
          action {
            text
            href
          }
        }
        testimonials {
          heading
          content
          quoteRefs
        }
        more {
          heading
          content
          items {
            image
            heading
            content
            action {
              text
              href
            }
          }
        }
        platform {
          heading
          left
          right {
            heading
            content
          }
        }
        logo {
          title
          image
          scale
        }
        join {
          heading
          content
          action {
            text
            href
          }
        }
      }
    }
  }
`;

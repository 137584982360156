import { useRef } from "react";
import { css } from "@emotion/react";
import AnimationData from "../animations/web.json";
import { CallToAction, Section } from "../components";
import { StyleShape6 } from "../components/Shapes";
import Theme from "../components/Theme";
import { useLottieAnimation } from "../core";
import { CallToActionData } from "../queries/DataTypes";

const styles = {
  base: css`
    display: flex;
    flex-direction: column;
    position: relative;

    ::before {
      ${StyleShape6};
      right: 0;
      top: -300px;
    }
  `,
  layout: css`
    display: grid;
    align-items: center;

    ${Theme.mq._sm} {
      margin-top: 48px;
      grid-template-rows: auto auto;
      grid-gap: 48px;
    }

    ${Theme.mq.md_} {
      grid-template-columns: 3fr 4fr;
      grid-gap: 48px;
    }

    ${Theme.mq.lg_} {
      grid-gap: 64px;
    }
  `,
  cta: css`
    ${Theme.mq._md} {
      > div {
        ${Theme.fontSizes.medium};
      }
      > h1 {
        ${Theme.fontSizes.h2};
      }
    }
  `,
};

export const SectionHomeHero = (props: CallToActionData) => {
  const animationRef = useRef<HTMLDivElement>(null);
  useLottieAnimation(animationRef, AnimationData);

  return (
    <header css={styles.base}>
      <Section sx={styles.layout}>
        <CallToAction appearance="primary" {...props} sx={styles.cta} />
        <div ref={animationRef} />
      </Section>
    </header>
  );
};

import { css } from "@emotion/react";
import { Section } from "../components";
import Theme from "../components/Theme";
import { getAssetImage } from "../core";

const styles = {
  layout: css`
    display: grid;
    grid-column-gap: 0px;
    grid-row-gap: 36px;
    align-items: center;
    justify-items: center;

    > img {
      width: auto;
      height: 40px;
      object-fit: contain;
    }

    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

    ${Theme.mq.xs} {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      > img {
        height: 30px;
      }
    }

    ${Theme.mq.xl} {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
  `,
  scale: (scale: number) => css`
    transform: scale(${scale});
  `,
};

export interface SectionHomeLogoProps {
  logo: Array<{
    title: string;
    image: string;
    scale?: number;
  }>;
}

export const SectionHomeLogo = ({ logo }: SectionHomeLogoProps) => {
  return (
    <Section>
      <div css={styles.layout}>
        {logo.map(({ title, image, scale }, index) => (
          <img
            key={`i${index}`}
            src={getAssetImage(`logo-x/${image}`)}
            alt={title}
            css={[scale && styles.scale(scale)]}
          />
        ))}
      </div>
    </Section>
  );
};

import { css } from "@emotion/react";
import { BlockMore, BlockMoreProps } from "../components";
import { StyleShape1 } from "../components/Shapes";

const styles = {
  base: css`
    display: flex;
    flex-direction: column;
    position: relative;

    ::after {
      ${StyleShape1};
      background-position: 50% -80px;
      top: 0;
      transform: rotate(180deg);
    }
  `,
};

export const SectionHomeMore = (props: BlockMoreProps) => (
  <div css={styles.base}>
    <BlockMore {...props} />
  </div>
);
